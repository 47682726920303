<template>
    <div>
        <b-card-code title="Add Product" :before-change="validationForm">
            <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>

                        <!-- locale -->
                        <b-col md="4">
                            <validation-provider #default="{ errors }" name="category" rules="required">
                                <b-form-group label="category" label-for="category">
                                    <v-select v-model="selectedCategory"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" value="id"
                                        :state="errors.length > 0 ? false : null"
                                        :options="categories.map(category => ({ id: category.id, name: category.name, code: category.code }))" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>

                        <b-col md="4">
                            <validation-provider #default="{ errors }" name="channel options" rules="required">
                                <b-form-group label="channel options" label-for="channel options">
                                    <v-select v-model="selectedChennelOption"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="text"
                                        :state="errors.length > 0 ? false : null" :options="channelOptions" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </b-form-group>
                            </validation-provider>
                        </b-col>


                        <b-col cols="12">
                            <hr />
                        </b-col>
                        <!-- submit button -->
                        <b-col style="text-align: -webkit-right;">
                            <b-button size="lg" variant="primary" type="submit" @click.prevent="validationForm">
                                Next
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-card-code>
        <!-- error handelr -->
        <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
            variant="danger">
            <h4 class="alert-heading">
                Alert
            </h4>
            <div class="alert-body">
                <ul v-for="values in errors_back" :key="values">
                    <li v-for="value in values" :key="value">{{ value }}</li>
                </ul>
            </div>
        </b-alert>
    </div>
</template>

<script>
import { heightTransition } from "@core/mixins/ui/transition";
import { required } from "@validations";
export default {
    mixins: [heightTransition],
    data() {
        return {
            ProfilePictureBase64: [],
            ProfilePictureBase642: [],
            activeSelect: null,
            errors_back: "",
            showDismissibleAlert: false,
            required,
            selectedCategory: null,
            categories: [],
            channelOptions: [
                {
                    value: 'new-portal',
                    text: "New Protal Product"
                },
                {
                    value: 'used-portal',
                    text: "Used Protal Product"
                },
                {
                    value: 'new-in-store',
                    text: "New In-store Product"
                },
                {
                    value: 'used-in-store',
                    text: "Used In-store Product"
                },
            ],
            selectedChennelOption: null,
        };
    },
    async created() {
        await this.getCategories();
    },


    watch: {

        selectedCategory: function () {
            const newOption = {
                value: 'used-unactive-protal',
                text: "Used Unactive Portal Product"
            };

            const exists = this.channelOptions.some(option => option.value === newOption.value);
            if (this.selectedCategory.code == 267 || this.selectedCategory.code == 4745) {


                if (!exists) {
                    this.channelOptions.push(newOption);
                }
            } else {

                if (exists) {
                    const index = this.channelOptions.findIndex(option => option.value === newOption.value);
                    if(index !== -1) {
                        this.channelOptions.splice(index, 1);
                    }
                }
            }
        },
    },
    methods: {
        // submit Brand API
        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {

                    if (this.selectedCategory.code == 267 || this.selectedCategory.code == 4745) {
                        if (this.selectedChennelOption.value == 'new-portal') {
                            this.$router.push({
                                path: '/categories/'+ this.selectedCategory.id +'/New/Product/Add',
                            })



                        } else if (this.selectedChennelOption.value == 'used-portal') {
                            this.$router.push({
                                path: '/categories/'+ this.selectedCategory.id + '/Used/Product/Add',
                            })


                        } else if (this.selectedChennelOption.value == 'new-in-store') {
                            this.$router.push({
                                path:  '/categories/'+ this.selectedCategory.id +'/New/In-Store/Product/Add',
                            })


                        } else if (this.selectedChennelOption.value == 'used-unactive-protal') {

                            this.$router.push({
                                path: '/categories/'+ this.selectedCategory.id +'/Used/Unactive/Product/Add',
                            })

                        }
                        else {
                            this.$router.push({
                                path:'/categories/'+ this.selectedCategory.id + '/Used/In-Store/Product/Add',
                            })

                        }


                    } else {
                        if (this.selectedChennelOption.value == 'new-portal' || this.selectedChennelOption.value == 'used-portal') {

                            this.$router.push({
                                path: '/category/' + this.selectedCategory.id + '/portal-product/' + (this.selectedChennelOption.value == 'new-portal' ? 'new' : 'used') + '/add',
                            })

                        } else {
                            this.$router.push({
                                path: '/category/' + this.selectedCategory.id + '/in-store-product/' + (this.selectedChennelOption.value == 'new-in-store' ? 'new' : 'used') + '/add',
                            })

                        }
                    }

                    // console.log(this.selectedCategory)

                }
            });
        },
        async getCategories() {
            await axios
                .get('products/valid-categories/get')
                .then((result) => {
                    this.categories = result.data.data
                })
                .catch((err) => {
                    this.errors_back.length = 0
                    if (err.response.data.data != null) {
                        if (this.isString(err.response.data.data)) {
                            this.errors_back.push({
                                error: err.response.data.data,
                            })
                        } else {
                            this.errors_back = err.response.data.data
                        }
                        this.showDismissibleAlert = true
                    } else {
                        this.errors_back = []
                        this.errors_back.push({
                            error: 'internal server error',
                        })
                        this.showDismissibleAlert = true
                    }
                })
        },



        isString(value) {
            return typeof value === "string" || value instanceof String;
        },
        // basic
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped></style>